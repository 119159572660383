import { Injectable, computed, inject, signal } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EntitiesResponse, Entity, LatestEntitiesResponse } from '../models/entity.model';
import { ResourcesService } from './resources.service';
import { Card } from '@lorient-emploi-front/shared/ui/components';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private readonly _apiService = inject(ApiService);
  private readonly _resourceService = inject(ResourcesService);
  readonly organizations = signal<Entity[]>([]);
  readonly cards = computed<Card[]>(() => this._resourceService.buildCards(this.organizations()));

  getOrganization(id: string): Observable<EntitiesResponse> {
    return this._apiService.get<EntitiesResponse>(`/organizations/${id}`);
  }

  getRandomOrganizations(): void {
    this._apiService.get<LatestEntitiesResponse>('/organizations/random').subscribe(response => {
      this.organizations.set(response.data);
    });
  }

  getOrganizations(page = 0): Observable<EntitiesResponse> {
    const params = new HttpParams().set('page', page.toString());
    return this._apiService.get<EntitiesResponse>('/organizations', params);
  }
}
