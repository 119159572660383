import { Injectable, computed, inject, signal } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EntitiesResponse, Entity, LatestEntitiesResponse } from '../models/entity.model';
import { Card } from '@lorient-emploi-front/shared/ui/components';
import { ResourcesService } from './resources.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private readonly _apiService = inject(ApiService);
  private readonly _resourceService = inject(ResourcesService);
  readonly companies = signal<Entity[]>([]);
  readonly cards = computed<Card[]>(() => this._resourceService.buildCards(this.companies()));

  getCompany(id: string): Observable<EntitiesResponse> {
    return this._apiService.get<EntitiesResponse>(`/companies/${id}`);
  }

  getRandomCompanies(): void {
    this._apiService.get<LatestEntitiesResponse>('/companies/random').subscribe(response => {
      this.companies.set(response.data);
    });
  }

  getCompanies(page = 0): Observable<EntitiesResponse> {
    const params = new HttpParams().set('page', page.toString());
    return this._apiService.get<EntitiesResponse>('/companies', params);
  }
}
