import { Injectable } from '@angular/core';
import { Entity } from '../models/entity.model';
import { Card } from '@lorient-emploi-front/shared/ui/components';
import { Occupation } from '../models/occupation.model';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  getUrl(type: string): string {
    switch (type) {
      case 'company':
        return 'entreprises';
    
      case 'event':
        return 'evenements';
    
      case 'job':
        return 'metiers';
    
      case 'location':
        return 'lieux';
    
      case 'news':
        return 'actualites';
    
      case 'organization':
        return 'organismes';
    
      case 'sector':
        return 'secteurs-activites';
    
      default:
        return 'recherche'
    }
  }

  getTypeName(type: string): string {
    switch (type) {
      case 'company':
        return 'Entreprises';
    
      case 'event':
        return 'Évènements';
    
      case 'job':
        return 'Métiers';
    
      case 'location':
        return 'Llieux';
    
      case 'news':
        return 'Actualités';
    
      case 'organization':
        return 'Organismes';
    
      case 'sector':
        return 'Secteurs d\'activités';
    
      default:
        return 'Entreprises'
    }
  }

  buildCards(entities: Entity[]): Card[] {
    return entities.map((entity) => ({
      id: entity.id.toString(),
      src: entity.image,
      title: entity.name,
      link: `/${this.getUrl(entity.type)}/${entity.slug}`,
      date: entity.date
    }));
  }

  buildCardsFromOccupations(occupations: Occupation[], imageSrc: string): Card[] {
    return occupations.map((occupation) => ({
      id: occupation.id.toString(),
      src: imageSrc,
      title: occupation.name,
      link: `/metier-entreprise/${occupation.slug}`,
    }));
  }
}
